<template>
  <v-card>
    <v-card-title class="align-start">
      <span>Cette semaine</span>

      <v-spacer></v-spacer>

      <v-btn
        icon
        small
        class="mt-n2 me-n3"
      >
        <v-icon size="22">
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <!-- Chart -->
      <vue-apex-charts
        :options="chartOptions"
        :series="money"
        height="210"
      ></vue-apex-charts>

      <div class="d-flex align-center">
        <h3 class="text-2xl font-weight-semibold me-4">
          45%
        </h3>
        <span>Your sales perfomance in 45% 🤩 better compare to last month</span>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import axios from 'axios'
// eslint-disable-next-line object-curly-newline
import { mdiDotsVertical, mdiTrendingUp, mdiCurrencyUsd } from '@mdi/js'

export default {
  components: {
    VueApexCharts,
  },
  data() {
    return {
      today: new Date(),
      students: [],
    }
  },
  computed: {
    lastMonday() {
      const currentDateObj = new Date()

      // eslint-disable-next-line no-mixed-operators
      return new Date(currentDateObj.setDate(currentDateObj.getDate() - (currentDateObj.getDay() + 6) % 7))
    },
    money() {
      const data = [0, 0, 0, 0, 0, 0, 0]
      this.students.forEach(student => {
        const day = new Date(student.created_at).getDay()
        data[day] += Number(student.price)
      })

      return [
        {
          name: 'Revenus',
          data,
        },
      ]
    },

  },
  watch: {
    // call again the method if the route changes
    $route: 'fetchData',

  },
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      axios.get(`${process.env.VUE_APP_API_URL}/students?created_at_gte=${this.lastMonday.toISOString().substring(0, 10)}T00:00:01&created_at_lte=${this.today.toISOString().substring(0, 10)}T23:59:59`)
        .then(response => {
          this.students = response.data
        })
        .catch(err => console.log(err))
    },
  },
  setup() {
    const chartOptions = {
      chart: {
        type: 'bar',
        toolbar: {
          show: false,
        },
        offsetX: -15,
      },
      plotOptions: {
        bar: {
          columnWidth: '40%',
          distributed: true,
          borderRadius: 8,
          startingShape: 'rounded',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tickPlacement: 'on',
        labels: {
          show: false,
          style: {
            fontSize: '12px',
          },
        },
      },
      yaxis: {
        show: true,
        tickAmount: 4,
        labels: {
          offsetY: 3,
          formatter: value => `${Number(value).toLocaleString('fr-FR')}€`,
        },
      },
      stroke: {
        width: [2, 2],
      },
      grid: {
        strokeDashArray: 12,
        padding: {
          right: 0,
        },
      },
    }

    return {
      chartOptions,

      icons: {
        mdiDotsVertical,
        mdiTrendingUp,
        mdiCurrencyUsd,
      },
    }
  },
}
</script>
