import { render, staticRenderFns } from "./StatistiquesPartenaires.vue?vue&type=template&id=8d8b8f1c&"
import script from "./StatistiquesPartenaires.vue?vue&type=script&lang=js&"
export * from "./StatistiquesPartenaires.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports