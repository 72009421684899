<template>
  <v-card>
    <v-card-title class="align-start">
      <span>Bénéfices totaux</span>

      <v-spacer></v-spacer>

      <v-btn
        icon
        small
        class="mt-n2 me-n3"
      >
        <v-icon size="22">
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <!-- Chart -->
      <vue-apex-charts
        :options="chartOptions"
        :series="money"
        height="210"
      ></vue-apex-charts>

      <div class="d-flex align-center">
        <h3 class="text-xl font-weight-semibold me-4 text-center">
          {{ Number(revenus).toLocaleString('fr-FR')+ '€' }} de revenus
        </h3>
        <span>Les bénéfices représentent {{ (Math.round(Number(revenus - money[0] - money[1])*100 / revenus)) }}% 🤩 des revenus !</span>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import axios from 'axios'
// eslint-disable-next-line object-curly-newline
import { mdiDotsVertical, mdiTrendingUp, mdiCurrencyUsd } from '@mdi/js'

export default {
  components: {
    VueApexCharts,
  },
  props: {
    formations: {
      type: Array,
      default: () => [],
    },
    partenaires: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      today: new Date(),
      students: [],
      revenus: 0,
    }
  },
  computed: {
    lastMonday() {
      const currentDateObj = new Date()

      // eslint-disable-next-line no-mixed-operators
      return new Date(currentDateObj.setDate(currentDateObj.getDate() - (currentDateObj.getDay() + 6) % 7))
    },
    money() {
      const data = [0, 0, 0]
      this.students.forEach(student => {
        if (student.formation) {
          data[0] += student.formation.prix
          this.revenus += student.price
        }
      })
      this.partenaires.forEach(partner => {
        if (partner.students.length > 0) {
          partner.students.forEach(stud => {
            data[1] += (stud.price * partner.tarif) / 100
          })
        }
      })
      data[2] = this.revenus - data[1] - data[0]

      return data
    },
  },
  watch: {
    // call again the method if the route changes
    $route: 'fetchData',

  },
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData()
    this.revenus = 0
  },
  methods: {
    fetchData() {
      this.loading = true
      axios.get(`${process.env.VUE_APP_API_URL}/students`)
        .then(response => {
          this.students = response.data
        })
        .catch(err => console.log(err))
    },
    add(accumulator, a) {
      return accumulator + a
    },
  },
  setup() {
    const chartOptions = {
      chart: {
        type: 'pie',
      },
      labels: ['Coûts Formations', 'Coûts Call Center', 'Bénéfices'],
      tooltip: {
        y: {
          formatter: value => `${Number(value).toLocaleString('fr-FR')}€`,
          title: {
            formatter: seriesName => seriesName,
          },
        },
      },
    }

    return {
      chartOptions,

      icons: {
        mdiDotsVertical,
        mdiTrendingUp,
        mdiCurrencyUsd,
      },
    }
  },
}
</script>
