<template>
  <v-card>
    <v-card-title class="align-start">
      <span>Revenus Totaux</span>
      <v-spacer></v-spacer>

      <v-btn
        icon
        small
        class="me-n3 mt-n2"
      >
        <v-icon>
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="my-7">
      <div class="d-flex align-center">
        <h1 class="text-4xl font-weight-semibold">
          {{ Number(totalRevenus).toLocaleString('fr-FR') }} €
        </h1>

        <div class="d-flex align-center mb-n3">
          <v-icon
            size="40"
            color="success"
          >
            {{ icons.mdiMenuUp }}
          </v-icon>
          <span class="text-base font-weight-medium success--text ms-n2">10%(todo)</span>
        </div>
      </div>

      <h4 class="mt-2 font-weight-medium">
        Boom boom boom
      </h4>
    </v-card-text>

    <v-card-text>
      <div
        v-for="(form ,index) in topForms"
        :key="form.id"
        :class="`d-flex align-start ${index >0 ? 'mt-8':''}`"
      >
        <div class="d-flex align-center flex-grow-1 flex-wrap">
          <div>
            <h4 class="font-weight-medium">
              {{ form.name }}
            </h4>
            <span class="text-xs text-no-wrap">{{ form.students }} apprenants </span>
          </div>

          <v-spacer></v-spacer>
        </div>
        <div class="ms-1">
          <p class="text--primary font-weight-medium mb-1">
            {{ Number(form.revenus).toLocaleString('fr-FR') }}€
          </p>
          <v-progress-linear
            :value="getMoy(form.revenus)"
            color="primary"
          ></v-progress-linear>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiDotsVertical, mdiMenuUp } from '@mdi/js'

export default {
  props: {
    formations: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      students: {},
      totalRevenus: 0,
    }
  },
  computed: {
    topForms() {
      const data = []
      this.formations.forEach(formation => {
        let studentCount = 0
        let revenus = 0
        formation.students.forEach(student => {
          studentCount += 1
          revenus += student.price
          this.totalRevenus += student.price
        })
        data.push({
          name: formation.nom,
          students: studentCount,
          revenus,
          id: formation.id,
        })
      })

      return data.sort((a, b) => b.revenus - a.revenus)
    },
  },
  watch: {

  },
  created() {

  },
  methods: {
    getMoy(a) {
      return (100 * a) / this.totalRevenus
    },
  },
  setup() {
    return {
      icons: { mdiDotsVertical, mdiMenuUp },
    }
  },
}
</script>
