<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="4"
      >
        <StatistiquesResumeSemaineVue></StatistiquesResumeSemaineVue>
      </v-col>
      <v-col
        cols="12"
        md="8"
      >
        <StatistiquesPartenairesVue :partners="partners"></StatistiquesPartenairesVue>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <StatistiquesTotalRevenus :formations="formations"></StatistiquesTotalRevenus>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <StatistiquesBeneficesTotauxVue
          :formations="formations"
          :partenaires="partners"
        ></StatistiquesBeneficesTotauxVue>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios'
import StatistiquesResumeSemaineVue from './StatistiquesResumeSemaine.vue'
import StatistiquesTotalRevenus from './StatistiquesTotalRevenus.vue'

// import StatistiquesProfitsVue from './StatistiquesProfits.vue'
import StatistiquesPartenairesVue from './StatistiquesPartenaires.vue'
import StatistiquesBeneficesTotauxVue from './StatistiquesBeneficesTotaux.vue'

export default {
  components: {
    StatistiquesResumeSemaineVue,
    StatistiquesTotalRevenus,

    //    StatistiquesProfitsVue,
    StatistiquesPartenairesVue,
    StatistiquesBeneficesTotauxVue,
  },
  data() {
    return {
      formations: [],
      partners: [],
    }
  },
  watch: {
    $route: 'fetchData',
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      axios.get(`${process.env.VUE_APP_API_URL}/formations`)
        .then(response => {
          this.formations = response.data
        })
        .catch(err => console.log(err))
      axios.get(`${process.env.VUE_APP_API_URL}/partenaires`)
        .then(response => {
          this.partners = response.data
        })
        .catch(err => console.log(err))
    },
  },
}
</script>

<style>

</style>
