<template>
  <v-card>
    <v-card-title class="align-start">
      <span>Revenus par partenaire</span>

      <v-spacer></v-spacer>

      <v-btn
        icon
        small
        class="mt-n2 me-n3"
      >
        <v-icon size="22">
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-skeleton-loader
        v-if="partners >= 0"
        class="mx-auto"
        max-width="500"
        type="card"
      ></v-skeleton-loader>
      <v-card v-else>
        <VueApexCharts
          type="bar"
          height="350"
          :options="chartOptions"
          :series="series"
        ></VueApexCharts>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
// eslint-disable-next-line object-curly-newline
import { mdiDotsVertical, mdiTrendingUp, mdiCurrencyUsd } from '@mdi/js'

export default {
  components: {
    VueApexCharts,
  },
  props: {
    partners: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      today: new Date(),
      series: [{
        data: [],
        name: 'Revenus',
        color: '#16b1ff',
      }, {
        data: [],
        name: 'Coûts',
        color: '#ffb400',
      }, {
        data: [],
        name: 'Bénéfices',
        color: '#56ca00',
      }],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded',
            distributed: false,
            borderRadius: 8,
            startingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          show: true,
          tickAmount: 4,
          labels: {
            offsetY: 3,
            formatter: value => `${Number(value).toLocaleString('fr-FR')}€`,
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter(val) {
              return `${Number(val).toLocaleString('fr-FR')} € `
            },
          },
        },
      },
    }
  },
  computed: {
  },
  watch: {
    partners: 'calculate',
  },
  created() {
    this.calculate()
  },
  methods: {
    calculate() {
      this.partners.forEach(partenaire => {
        this.chartOptions.xaxis.categories.push(partenaire.nom)
        let partRevenus = 0
        partenaire.students.forEach(student => {
          partRevenus += student.price
        })
        const partCout = partRevenus * (partenaire.tarif / 100)
        const partBenef = partRevenus - partCout
        console.log(partRevenus)
        this.series[0].data.push(partRevenus)
        this.series[1].data.push(partCout)
        this.series[2].data.push(partBenef)
      })
    },
  },
  setup() {
    return {
      icons: {
        mdiDotsVertical,
        mdiTrendingUp,
        mdiCurrencyUsd,
      },
    }
  },
}
</script>
